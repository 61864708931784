import React, {useEffect} from "react"
import ProductVariation from "./ProductVariation"
import { dataLayerUpdate } from "../api/dataLayer/gaDataLayer"
import { getRequiredProductDataForGA } from "../utils/productsHelper";

const VariableProduct = ({ location, pageContext }) => {

  const variantData = JSON.parse(pageContext.variantData)
  const allVariants = JSON.parse(pageContext.allVariants)

  useEffect(() => {
    dataLayerUpdate("view_item", getRequiredProductDataForGA(variantData))
    return () => {}
  }, [])

  return (
    <>
      <ProductVariation
        location={location}
        variantData={variantData}
        allVariants={allVariants}
        allColours={pageContext.allColours}
        isGeneric={pageContext.isGeneric}
      />
    </>
  )
}

export default VariableProduct
