import {  Link } from "gatsby"
import Tooltip from "rc-tooltip"
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import * as style from "./ColorSelector.module.scss"
import "rc-tooltip/assets/bootstrap.css"

const ColorSelector = ({
  selectedSlug,
  activeSize,
  breadcrumbData,
  allVariants,
  showLabel,
  allColours
}) => {
  let tooltipMsg = activeSize
    ? `This colour is unavailable in ${activeSize}. Click for alternative, or stock notification option.`
    : "This colour is unavailable. Click for alternative, or stock notification option."

  let coloursToBuild = allVariants.map(variant => (
      {
        colour: variant.colour,
        slug: variant.slug,
        stockStatus: variant.variantData.stockStatus,
        stockQuantity: variant.variantData.stockQuantity
      }
    )
  )

  return (
    <div className={style.colorSelectorBlock}>
      {showLabel !== false && <p className={style.colorSelectorLable}>Colour</p>}
      <ul className={style.colorSelectorOptions}>
        {coloursToBuild.map((colourVariant, index) => {
          let displayColour ="transparent"
          const variantColour = allColours[colourVariant.colour]
          if (variantColour !== undefined) {
              displayColour = variantColour.hexcode
          } else {
              console.error('FIXME variantColour undefined for ' + colourVariant.colour + ' product:' + colourVariant.slug)
          }

          let customCss
          let selectionBoxShadow

          if (variantColour !== undefined && variantColour.lightAccent) {
            selectionBoxShadow = `box-shadow: 0 0 0 3px white, 0 0 0 5px #d2d2d2;`
            customCss = `box-shadow: 0 0 0 3px white, 0 0 0 5px  #e9e6e6;
            border: 1px solid #e9e6e6;
            &:hover {
              ${selectionBoxShadow}
            }`
          } else {
            selectionBoxShadow = `box-shadow: 0 0 0 3px white, 0 0 0 5px ${displayColour};`
            customCss = `box-shadow: white 0 0 0 3px, rgb(226, 233, 234) 0 0 0 5px;
            &:hover {
              ${selectionBoxShadow}
            }`
          }





          if(colourVariant.slug === selectedSlug){
            return (
              <li key={index}>
                <span aria-label="display colour" role="button" className={style.singleColorItem}
                css={css`background-color: ${displayColour};
                    ${selectionBoxShadow};
                    cursor: default;
                  `} ></span>
              </li>
            )
          }

          if(colourVariant.stockStatus === "IN_STOCK" || colourVariant.stockStatus === 'ON_BACKORDER'){
            return (
              <li key={index}>
                <Link
                  className={style.singleColorItem}
                  to={colourVariant.slug}
                  state={{ breadcrumbData: breadcrumbData }}
                  css={css`
                      background-color: ${displayColour};
                      ${customCss}
                    `}/>
              </li>
            )
          } else {
            return (
              <li key={index}>
                <Tooltip
                  overlay={tooltipMsg}
                  placement="top">
                  <Link
                    className={`${style.singleColorItem} ${style.unavailableItem}`}
                    to={colourVariant.slug}
                    state={{ breadcrumbData: breadcrumbData }}
                    css={css`
                      background-color: ${displayColour};
                      ${customCss}
                    `}/>
                </Tooltip>
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}

export default ColorSelector
