// extracted by mini-css-extract-plugin
export var bgGrey100 = "ColorSelector-module--bg-grey-100--698bc";
export var bgGrey150 = "ColorSelector-module--bg-grey-150--72ae5";
export var bgGrey200 = "ColorSelector-module--bg-grey-200--bb2b8";
export var bgGrey300 = "ColorSelector-module--bg-grey-300--89884";
export var bgGrey400 = "ColorSelector-module--bg-grey-400--97675";
export var bgGrey500 = "ColorSelector-module--bg-grey-500--0e07f";
export var bgGrey600 = "ColorSelector-module--bg-grey-600--f976b";
export var bgGrey700 = "ColorSelector-module--bg-grey-700--8ccb4";
export var bgGrey800 = "ColorSelector-module--bg-grey-800--ce988";
export var bgGrey900 = "ColorSelector-module--bg-grey-900--95a9e";
export var colorSelectorBlock = "ColorSelector-module--colorSelectorBlock--bd306";
export var colorSelectorLable = "ColorSelector-module--colorSelectorLable--343ee";
export var colorSelectorOptions = "ColorSelector-module--colorSelectorOptions--fb04c";
export var colourOption = "ColorSelector-module--colourOption--043a2";
export var singleColorItem = "ColorSelector-module--singleColorItem--33350";
export var textGrey100 = "ColorSelector-module--text-grey-100--58f9a";
export var textGrey150 = "ColorSelector-module--text-grey-150--97e8e";
export var textGrey200 = "ColorSelector-module--text-grey-200--081d6";
export var textGrey300 = "ColorSelector-module--text-grey-300--37bbd";
export var textGrey400 = "ColorSelector-module--text-grey-400--ffbf9";
export var textGrey500 = "ColorSelector-module--text-grey-500--babec";
export var textGrey600 = "ColorSelector-module--text-grey-600--c4f36";
export var textGrey700 = "ColorSelector-module--text-grey-700--3393e";
export var textGrey800 = "ColorSelector-module--text-grey-800--89b56";
export var textGrey900 = "ColorSelector-module--text-grey-900--35ef1";
export var unavailableItem = "ColorSelector-module--unavailableItem--316b0";
export var variantLink = "ColorSelector-module--variantLink--34b95";