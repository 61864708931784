import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { basketSelectors, basketActions } from "../state/basket"

import Layout from "../components/Layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import classNames from "classnames"

import Breadcrumb from "../components/UI/Breadcrumb/Breadcrumb"

import ProductImageSlider from "./ProductUI/ProductImageSlider"
import Price from "./ProductUI/Price"
import ColorSelector from "./ProductUI/ColorSelector2"
import SizeSelector from "./ProductUI/SizeSelector2"
import AddToBasket from "./ProductUI/AddToBasket"
import { ModalPortal } from "../portals/Modal.portal"
import AddedToBasketModal from "./ProductUI/AddedToBasketModal"
import BasketErrorModal from "./ProductUI/BasketErrorModal"
import OutOfStock from "./ProductUI/OutOfStock"
import Installments from "./ProductUI/Installments"
import Delivery from "./ProductUI/Delivery"
import SpecsSection from "./ProductUI/SpecsSection"
import UpsellSection from "./ProductUI/UpsellSection"
import {
  getTopLevelProductCategory,
  getBreadcrumbDataFromCategoryObj,
  setInstallments,
  getSpecs,
  getProductBrand
} from "../utils/productsHelper"
import { getSeoAvailiability, makeSeoProductImages, makeProductSeoHeadTags, makeProductPageSchema } from "../utils/seoHelper"
import { convertFlatListToHierarchical } from "../utils/hierarchicalize"
import { prop } from "ramda"
import {BeforeAddToBasketNotification} from "./ProductUI/BeforeAddToBasketNotification";
import { rawPrice as _rawPrice} from "../utils/pricesHelper"

const ProductVariation = ({
  location,
  variantData,
  allVariants,
  basket,
  isAddedToBasketModalVisible,
  hideAddedToBasketModal,
  isBasketError,
  hideBasketError,
  allColours,
  isGeneric
}) => {
  let {
    name,
    productCategories,
    description,
    shortDescription,
    galleryImages,
    featuredImage: genericFeaturedImage,
    attributes,
    upsell,
    defaultAttributes,
    seo
  } = variantData.genericProductData // generic data of this product (shared with other variants)

  // const defaultCategory = ( variantData &&
  //   variantData.genericProductData &&
  //   variantData.genericProductData.productCategories &&
  //   variantData.genericProductData.productCategories.nodes &&
  //   variantData.genericProductData.productCategories.nodes &&
  //   variantData.genericProductData.productCategories.nodes.length > 0 &&
  //   variantData.genericProductData.productCategories.nodes[0].name ) || ""

  const {
    id,
    stockStatus,
    stockQuantity,
    price,
    regularPrice,
    salePrice,
    sku
  } = variantData.variantData

  const onSale = variantData.onSale

  variantData.genericProductData.topLevelProductCategory = getTopLevelProductCategory(productCategories)

  const installments = setInstallments(variantData.genericProductData.topLevelProductCategory, _rawPrice(price))
  let installmentsPrice
  if (installments) {
    installmentsPrice = (_rawPrice(price) / installments).toFixed(2).toLocaleString()
  }

  let specs
  if (attributes && attributes.nodes) {
    specs = getSpecs(attributes)
    variantData.genericProductData.brand = getProductBrand(attributes)
    variantData.genericProductData.sku = sku
  } else {
    console.warn(
      `# Crumbs! Variable product, ${name} (id: ${id}), has no attributes.`
    )
  }

  const categoryTree = convertFlatListToHierarchical(productCategories.nodes, {
    idKey: "id",
    childrenKey: "children",
    parentKey: "parentId",
  })

  let breadcrumbData
  let locationBreadcrumb

  locationBreadcrumb = prop("breadcrumbData", location.state)

  if (locationBreadcrumb) {
    breadcrumbData = location.state.breadcrumbData
  } else {
    breadcrumbData = getBreadcrumbDataFromCategoryObj(categoryTree)
  }

  let productPageTitle
  if (isGeneric || !variantData.colour) {
    productPageTitle = `${name}`
  } else {
    productPageTitle = `${name} ${variantData.colour}`
  }

  const StockHandler = ({ stockQuantity, stockStatus }) => {
    const basketItem = basket.filter(item => item.itemId === id)
    let stockUpdate

    if (basketItem) {
      stockUpdate = prop("updatedStock", basketItem[0])
    }

    if (isBasketError) {
      return (
        <ModalPortal hide={hideBasketError} size="lg" centered={true}>
          <BasketErrorModal product={variantData} />
        </ModalPortal>
      )
    } else if (isAddedToBasketModalVisible) {
      return (
        <ModalPortal
          hide={hideAddedToBasketModal}
          size="lg"
          centered={true}
          heading="Added to basket">
          <AddedToBasketModal product={variantData} />
        </ModalPortal>
      )
    }
    if(isGeneric) {
      return <p>Please select the variant</p>
    } else if ((stockQuantity === null && stockStatus === "IN_STOCK" )
        || (stockQuantity && !stockUpdate)
        || (stockUpdate > 0)) {
      return <AddToBasket location={location} product={variantData} buttonTitle="Add to basket" />
    } else if(Boolean(Number(process.env.BACKORDER_ENABLED)) && stockStatus === "ON_BACKORDER"){
      return <AddToBasket location={location} product={variantData} buttonTitle="Add to basket" />
    } else {
      return <OutOfStock product={variantData} location={location}  />
    }
  }

  const promotionDetails = variantData.genericProductData?.beforeAddToBasketNotification?.productPromotions
  const rawPrice = _rawPrice(price)

  const availability = getSeoAvailiability(stockStatus, stockQuantity)
  const seoImages = makeSeoProductImages([...galleryImages.nodes, genericFeaturedImage.node])

  const productPageSchema = makeProductPageSchema(
      seo.schema.raw,
      name,
      seoImages.schema,
      seo.metaDesc,
      sku,
      location.pathname,
      availability.schema,
      variantData.genericProductData.brand,
      rawPrice,
      onSale,
      breadcrumbData
  )

  return (
    <Layout
      attributeColour={variantData.colour}
      seoTitle={seo.title}
      location={location}
      seoHeadTags={seo.headTags}
      schema={productPageSchema}>
      {breadcrumbData && <Breadcrumb data={breadcrumbData} />}
      <Container fluid="xl">
        <section className="pt-0 pb-3 pt-md-5 pb-md-5">
          <Row className={productDescriptionRowClass}>
            <Col
              className={productDescriptionImgColClass}
              sm={12}
              md={6}
              xl={5}>
              <ProductImageSlider
                onSale={onSale}
                selectedSlug={location.pathname}
                variantData={variantData}
                productGallery={galleryImages.nodes}
                genericFeaturedImage={genericFeaturedImage}
                youtubeVideoId={variantData.genericProductData.product_videos.youtubeVideoId}
              />
            </Col>
            <Col
              className={productDescriptionTextColClass}
              sm={12}
              md={6}
              xl={6}>
              <h1 className={productNameClass}>{productPageTitle}</h1>
              {regularPrice && (
                <Price
                  onSale={onSale}
                  regularPrice={regularPrice}
                  salePrice={salePrice && salePrice}
                  promotionDetails={promotionDetails}
                />
              )}

              {variantData.colour && (
                <ColorSelector
                  selectedSlug={location.pathname}
                  activeColour={variantData.colour}
                  activeSize={variantData.size}
                  breadcrumbData={breadcrumbData}
                  allVariants={variantData.size ? allVariants.filter(item => item.size === variantData.size) : allVariants}
                  allColours={allColours}
              >
                </ColorSelector>
              )}

              {variantData.size && (
                <SizeSelector
                  selectedSlug={location.pathname}
                  activeColour={variantData.colour}
                  activeSize={variantData.size}
                  breadcrumbData={breadcrumbData}
                  allVariants={variantData.colour ? allVariants.filter(item => item.colour === variantData.colour) : allVariants}>
                </SizeSelector>
              )}

              <BeforeAddToBasketNotification product={variantData} basket={basket} />

              <StockHandler
                stockStatus={stockStatus}
                stockQuantity={
                  stockQuantity !== undefined ? stockQuantity : null
                }
              />

              {installments && Boolean(Number(process.env.IS_KLARNA_ENABLED)) && (
              <Installments
                  installments={installments}
                  installmentsPrice={installmentsPrice}
              />
              )}
              <Delivery />

              {shortDescription && (
                <p
                  className={shortDescriptionClass}
                  dangerouslySetInnerHTML={{
                    __html: shortDescription,
                  }}
                />
              )}
            </Col>
          </Row>
        </section>
        {attributes && attributes.nodes && (
          <SpecsSection
            category={variantData.genericProductData.topLevelProductCategory}
            description={description}
            specification={specs.specification}
            specificationLeft={specs.specificationLeft}
            specificationRight={specs.specificationRight}
        />
      )}
        {upsell.nodes && <UpsellSection upsells={upsell.nodes} />}
      </Container>
    </Layout>
  )
}

const productDescriptionRowClass = classNames(
    "d-flex",
    "justify-content-between",
    "mx-md-0"
)
const productDescriptionImgColClass = classNames("m-xl-0", "p-xl-0","p-0")
const productDescriptionTextColClass = classNames("my-3", "m-xl-0", "p-xl-0")

const productNameClass = classNames("text-uppercase")
const shortDescriptionClass = classNames("d-none", "d-lg-block")

const mapStateToProps = createStructuredSelector({
  basket: basketSelectors.getItems,
  isAddedToBasketModalVisible: basketSelectors.isAddedToBasketModalVisible,
  isBasketError: basketSelectors.isBasketError,
})
const mapDispatchToProps = {
  showAddedToBasketModal: basketActions.showAddedToBasketModal,
  hideAddedToBasketModal: basketActions.hideAddedToBasketModal,
  hideBasketError: basketActions.hideBasketError,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariation)
