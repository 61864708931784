// extracted by mini-css-extract-plugin
export var active = "SizeSelector-module--active--8f4ca";
export var bgGrey100 = "SizeSelector-module--bg-grey-100--691f1";
export var bgGrey150 = "SizeSelector-module--bg-grey-150--25df1";
export var bgGrey200 = "SizeSelector-module--bg-grey-200--9a52f";
export var bgGrey300 = "SizeSelector-module--bg-grey-300--7a9ab";
export var bgGrey400 = "SizeSelector-module--bg-grey-400--7fc4e";
export var bgGrey500 = "SizeSelector-module--bg-grey-500--18525";
export var bgGrey600 = "SizeSelector-module--bg-grey-600--612f3";
export var bgGrey700 = "SizeSelector-module--bg-grey-700--2b207";
export var bgGrey800 = "SizeSelector-module--bg-grey-800--c334b";
export var bgGrey900 = "SizeSelector-module--bg-grey-900--1d9f1";
export var options = "SizeSelector-module--options--0f8f1";
export var selectedItem = "SizeSelector-module--selectedItem--04c7f";
export var sizeBoxBlock = "SizeSelector-module--sizeBoxBlock--4cd70";
export var sizeBoxLable = "SizeSelector-module--sizeBoxLable--96e0e";
export var sizeOptionsBox = "SizeSelector-module--sizeOptionsBox--308bd";
export var textGrey100 = "SizeSelector-module--text-grey-100--8702b";
export var textGrey150 = "SizeSelector-module--text-grey-150--cd652";
export var textGrey200 = "SizeSelector-module--text-grey-200--ccc8c";
export var textGrey300 = "SizeSelector-module--text-grey-300--18556";
export var textGrey400 = "SizeSelector-module--text-grey-400--a44e7";
export var textGrey500 = "SizeSelector-module--text-grey-500--bc0d7";
export var textGrey600 = "SizeSelector-module--text-grey-600--0ceed";
export var textGrey700 = "SizeSelector-module--text-grey-700--455aa";
export var textGrey800 = "SizeSelector-module--text-grey-800--e980f";
export var textGrey900 = "SizeSelector-module--text-grey-900--62ca2";
export var unavailableOption = "SizeSelector-module--unavailableOption--278c9";