import React from "react"
import { Link } from "gatsby"
import Tooltip from "rc-tooltip"
import "rc-tooltip/assets/bootstrap.css"
import * as style from "./SizeSelector.module.scss"
import { getDisplaySizes } from "../../utils/coloursAndSizes"

const SizeSelector = ({
  selectedSlug,
  activeColour,
  breadcrumbData,
  allVariants
}) => {

  const sizesData = getDisplaySizes(allVariants)
  const sizeList = process.env.CLOTHES_SIZE_ORDER.split(',')
  let tooltipMsg = activeColour
    ? `This size is unavailable in ${activeColour}. Click for alternative, or stock notification option.`
    : "This size is unavailable. Click for alternative, or stock notification option."
  return (
    <div className={style.sizeBoxBlock}>
      <p className={style.sizeBoxLable}>Size</p>
      <ul className={style.sizeOptionsBox}>
        {sizesData.sizesToBuild
          .sort((a,b) => sizeList.indexOf(a.displaySize.toUpperCase()) - sizeList.indexOf(b.displaySize.toUpperCase()))
          .map((sizeVariant) => {
          if(sizeVariant.slug === selectedSlug){
            return (
              <li key={sizeVariant.size} className={style.selectedItem}>
                  <span>{sizeVariant.displaySize ? sizeVariant.displaySize : sizeVariant.size}</span>
              </li>
            )
          }

          if(sizeVariant.stockStatus === "IN_STOCK" || sizeVariant.stockStatus === "ON_BACKORDER"){
            return (
              <li
                className={`${style.options}`}
                key={sizeVariant.size}>
                <Link
                  to={sizeVariant.slug}
                  state={{ breadcrumbData: breadcrumbData }}>
                  {sizeVariant.displaySize
                    ? sizeVariant.displaySize
                    : sizeVariant.size}
                </Link>
              </li>
            )
          } else {
            return (
              <li
                key={sizeVariant.size}>
                <Tooltip
                  overlay={tooltipMsg}
                  placement="top">
                  <Link
                    className={`${style.options} ${style.unavailableOption}`}
                    to={sizeVariant.slug}
                    state={{ breadcrumbData: breadcrumbData }}>
                    {sizeVariant.displaySize
                      ? sizeVariant.displaySize
                      : sizeVariant.size}
                  </Link>
                </Tooltip>
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}

export default SizeSelector
